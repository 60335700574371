<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="850px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Danh sách chi tiết kpi</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="form.year"
                    label="Năm"
                    outlined
                    disabled
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="form.month"
                    label="Tháng"
                    disabled
                    outlined
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="staff_name"
                    label="Tên nhân viên"
                    disabled
                    outlined
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <CustomTable
                  :headers="headers"
                  :items="tableData"
                  hide-default-footer
                  :loading="isloading"
                  loading-text="Đang tải dữ liệu ..."
                  classPaging="pt-2"
                >
                  <template v-slot:[`item.approach`]="{ item }">
                    <span v-if="item.approach == 0" class="badge primary">
                      Khách hàng đăng kí
                    </span>
                    <span v-if="item.approach == 1" class="badge error">
                      Khách hàng đã tiếp cận
                    </span>
                    <span v-if="item.approach == 2" class="badge success">
                      Khách hàng mới
                    </span>
                  </template>

                  <template v-slot:[`item.action`]="{ item }">
                    <v-menu v-if="item.approach == 0 || item.approach == 1">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon color="textColor">mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-if="item.approach == 0"
                          @click="edit(item, 1)"
                        >
                          <v-list-item-title>
                            <v-icon class="mr-2"> mdi-pencil </v-icon>
                            khách hàng đã tiếp cận</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item
                          v-if="item.approach == 1"
                          @click="edit(item, 2)"
                        >
                          <v-list-item-title>
                            <v-icon class="mr-2"> mdi-pencil </v-icon>
                            Khách hàng mới</v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </CustomTable>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" @click="exit()"> Thoát </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import KpiDetail from "@/api/kpidetail";
export default {
  data: () => ({
    dialog: false,
    form: {
      year: "",
      month: "",
      staff_id: "",
    },
    isloading: false,
    staff_name: "",
    id: "",
    required_rule: [(v) => !!v || "Không thể bỏ trống"],
    numberRules: [
      (v) => Number.isInteger(Number(v)) || "Phải là kiểu số",
      (v) => v > 0 || "Phải lớn hơn 0",
    ],
    headers: [
      { text: "Năm", value: "year" },
      { text: "Tháng", value: "month" },
      { text: "Tên khách hàng", value: "customer_name" },
      { text: "Địa chỉ", value: "add" },
      { text: "Ngành nghề sản xuất", value: "career" },
      { text: "Đánh giá", value: "vote" },
      { text: "Mô hình giống mô hình hiện tại", value: "model_same" },
      { text: "Ghi chú", value: "note" },
      { text: "Trạng thái tiếp cận", value: "approach" },
      {
        text: "Hành động",
        value: "action",
        sortable: false,
        align: "center",
        width: "150",
      },
    ],
    tableData: [],
  }),
  methods: {
    showFormEdit(data) {
      this.id = data.id;
      this.dialog = true;
      this.form = {
        year: data.year,
        month: data.month,
        staff_id: data.staff_id,
      };
      this.staff_name = data.staff.name;
      this.getlistkpi();
    },
    async getlistkpi() {
      this.isloading = true;
      const res = await KpiDetail.get(this.form);
      this.tableData = res.data;
      this.isloading = false;
    },
    async edit(item, type) {
      this.isloading = true;
      let data = {
        id: item.id,
        type: type,
        year: this.form.year,
        month: this.form.month,
        staff_id: this.form.staff_id,
      };
      const res = await KpiDetail.updateApproach(data);
      this.$emit("on-done")
      this.getlistkpi();
      this.isloading = false;
    },
    exit() {
      this.$refs.form.reset();
      this.tableData = [];
      this.$emit("on-done");
      this.dialog = false;
    },
  },
  watch: {
    form: {
      deep: true,
      handler: function () {},
    },
  },
};
</script>