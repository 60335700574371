import { AApi } from "@/service/AApi";
const API = new AApi(
  { url: "kpiplans" },
  {
    kpiplansExport: {
      async request(data) {
        return this.sdk({
          url: "/kpiplan-export",
          responseType: "blob",
          params: data
        });
      },
    },
    importKpiPlan: {
      async request(data) {
        return this.sdk({
          url: "/kpiplan-import",
          method: "post",
          data,
        });
      },
    },

  }
);
export default API;