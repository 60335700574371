import { AApi } from "@/service/AApi";
const API = new AApi(
  { url: "kpidetail" },
  {
    kpidetailexport: {
      async request(data) {
        return this.sdk({
          url: "/kpidetail-export",
          responseType: "blob",
          params: data
        });
      },
    },
    get: {
      async request(data) {
        return this.sdk({
          url: "kpi-detail-list",
          method: "get",
          params: data
        });
      },
    },
    updateApproach: {
      async request(data) {
        return this.sdk({
          url: "kpi-detail-approach",
          method: "put",
          params: data
        });
      },
    },
   
  }
);

export default API;
