var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageListLayout',{attrs:{"icon":"mdi-folder-account","title":"Danh sách kpi tăng trưởng thị phần ","subTitle":"Danh sách kpi tăng trưởng thị phần","titleAdd":"Tạo mới kpi tăng trưởng thị phần"},on:{"add":_vm.goCreate,"reset-filter":_vm.resetFilter}},[_c('template',{slot:"side-right"},[_c('div',{staticClass:"d-flex align-center"},[_c('Detail',{ref:"form",staticClass:"mr-5",on:{"on-done":_vm.getAll}}),_c('Export',{ref:"export"}),_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":""},on:{"click":function($event){return _vm.importData()}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-cloud-upload")]),_vm._v(" Import File ")],1),_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":""},on:{"click":function($event){return _vm.handle_download_templace()}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-cloud-download")]),_vm._v(" Tải file mẫu ")],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"icon":"","loading":_vm.loadingExport},on:{"click":function($event){return _vm.exportExcel()}}},on),[_c('v-icon',[_vm._v(" mdi-file-export-outline ")])],1)]}}])},[_c('span',[_vm._v("Kết xuất dữ liệu")])])],1)]),_c('template',{slot:"filter"},[_c('div',{staticClass:"mt-4"},[_c('v-autocomplete',{attrs:{"label":"Năm ","items":_vm.yearList,"item-text":"name","item-value":"id","multiple":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.formSearch.year),callback:function ($$v) {_vm.$set(_vm.formSearch, "year", $$v)},expression:"formSearch.year"}})],1),_c('div',{staticClass:"mt-4"},[_c('v-autocomplete',{attrs:{"label":"Tháng ","items":_vm.monthList,"item-text":"name","item-value":"id","multiple":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.formSearch.month),callback:function ($$v) {_vm.$set(_vm.formSearch, "month", $$v)},expression:"formSearch.month"}})],1),_c('div',{staticClass:"mt-4"},[_c('v-autocomplete',{attrs:{"label":"Nhân viên ","items":_vm.employers,"item-text":"name","item-value":"id","multiple":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.formSearch.staff_id),callback:function ($$v) {_vm.$set(_vm.formSearch, "staff_id", $$v)},expression:"formSearch.staff_id"}})],1)]),_c('CustomTable',{attrs:{"headers":_vm.headers,"items":_vm.tableData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","loading":_vm.loading,"loading-text":"Đang tải dữ liệu ...","pageCount":_vm.pageCount,"classPaging":"pt-2"},on:{"update:page":function($event){_vm.page=$event},"change-page":_vm.changePage},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.edit(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-pencil ")]),_vm._v(" Chi tiết")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleted(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-delete ")]),_vm._v(" Xóa")],1)],1)],1)],1)]}}],null,true)}),_c('ImportFile',{ref:"formImport",on:{"on-done":_vm.getAll}}),_c('Templace',{ref:"templace"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }