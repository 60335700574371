var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"850px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Danh sách chi tiết kpi")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Năm","outlined":"","disabled":"","clearable":""},model:{value:(_vm.form.year),callback:function ($$v) {_vm.$set(_vm.form, "year", $$v)},expression:"form.year"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Tháng","disabled":"","outlined":"","clearable":""},model:{value:(_vm.form.month),callback:function ($$v) {_vm.$set(_vm.form, "month", $$v)},expression:"form.month"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Tên nhân viên","disabled":"","outlined":"","clearable":""},model:{value:(_vm.staff_name),callback:function ($$v) {_vm.staff_name=$$v},expression:"staff_name"}})],1)],1),_c('v-row',[_c('CustomTable',{attrs:{"headers":_vm.headers,"items":_vm.tableData,"hide-default-footer":"","loading":_vm.isloading,"loading-text":"Đang tải dữ liệu ...","classPaging":"pt-2"},scopedSlots:_vm._u([{key:"item.approach",fn:function(ref){
var item = ref.item;
return [(item.approach == 0)?_c('span',{staticClass:"badge primary"},[_vm._v(" Khách hàng đăng kí ")]):_vm._e(),(item.approach == 1)?_c('span',{staticClass:"badge error"},[_vm._v(" Khách hàng đã tiếp cận ")]):_vm._e(),(item.approach == 2)?_c('span',{staticClass:"badge success"},[_vm._v(" Khách hàng mới ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.approach == 0 || item.approach == 1)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(item.approach == 0)?_c('v-list-item',{on:{"click":function($event){return _vm.edit(item, 1)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-pencil ")]),_vm._v(" khách hàng đã tiếp cận")],1)],1):_vm._e(),(item.approach == 1)?_c('v-list-item',{on:{"click":function($event){return _vm.edit(item, 2)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-pencil ")]),_vm._v(" Khách hàng mới")],1)],1):_vm._e()],1)],1):_vm._e()]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"default"},on:{"click":function($event){return _vm.exit()}}},[_vm._v(" Thoát ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }