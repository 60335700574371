<template>
  <PageListLayout
    icon="mdi-folder-account"
    title="Danh sách kpi tăng trưởng thị phần "
    subTitle="Danh sách kpi tăng trưởng thị phần"
    @add="goCreate"
    @reset-filter="resetFilter"
    titleAdd="Tạo mới kpi tăng trưởng thị phần"
  >
    <template slot="side-right">
      <div class="d-flex align-center">
        <!--Tao moi-->
        <Detail @on-done="getAll" ref="form" class="mr-5" />
        <Export ref="export" />
        <div class="d-flex">
          <v-btn class="mr-4" outlined @click="importData()">
            <v-icon class="mr-1">mdi-cloud-upload</v-icon>
            Import File
          </v-btn>
          <v-btn class="mr-4" outlined @click="handle_download_templace()">
            <v-icon class="mr-1">mdi-cloud-download</v-icon>
            Tải file mẫu
          </v-btn>
        </div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mr-1"
              icon
              v-on="on"
              :loading="loadingExport"
              @click="exportExcel()"
            >
              <v-icon> mdi-file-export-outline </v-icon>
            </v-btn>
          </template>
          <span>Kết xuất dữ liệu</span>
        </v-tooltip>
      </div>
    </template>

    <template slot="filter">
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.year"
          label="Năm "
          :items="yearList"
          item-text="name"
          item-value="id"
          multiple
          outlined
          dense
          hide-details
        ></v-autocomplete>
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.month"
          label="Tháng "
          :items="monthList"
          item-text="name"
          item-value="id"
          multiple
          outlined
          dense
          hide-details
        ></v-autocomplete>
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.staff_id"
          label="Nhân viên "
          :items="employers"
          item-text="name"
          item-value="id"
          multiple
          outlined
          dense
          hide-details
        ></v-autocomplete>
      </div>
    </template>
    <CustomTable
      :headers="headers"
      :items="tableData"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :loading="loading"
      loading-text="Đang tải dữ liệu ..."
      :pageCount="pageCount"
      @change-page="changePage"
      classPaging="pt-2"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="textColor">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="edit(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-pencil </v-icon>
                Chi tiết</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="deleted(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-delete </v-icon>
                Xóa</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </CustomTable>
    <ImportFile ref="formImport" @on-done="getAll"></ImportFile>
    <Templace ref="templace"></Templace>
  </PageListLayout>
</template>
<script>
import { debounce } from "lodash";
import Kpiplans from "@/api/kpiplan";
import { getNhanVienKinhDoanh } from "@/api/nhanvien";
import Detail from "./detail";
import { generateArrayOfYears } from "@/utils/date.js";
import { monthList } from "@/constants/time";
import ImportFile from "./import-file";
import Templace from "./export-templace";
import { saveAs } from "file-saver";
export default {
  components: { Detail, Templace, ImportFile },
  data() {
    return {
      formSearch: {
        year: [],
        month: [],
        staff_id: [],
      },
      yearList: generateArrayOfYears(10),
      monthList,
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
      tableData: [],
      btnLoading: false,
      menu: {},
      loading: false,
      search: "",
      employers: [],
      customersList: [],
      costtypeall: [],
      loadingExport: false,
      headers: [
        { text: "Năm", value: "year" },
        { text: "Tháng", value: "month" },
        { text: "Nhân viên", value: "staff.name" },
        { text: "Số khách hàng đăng kí", value: "customer_register" },
        { text: "Số khách hàng tiếp cận", value: "customer_accost" },
        { text: "Số khách hàng mới", value: "customer_new" },
        {
          text: "Hành động",
          value: "action",
          sortable: false,
          align: "center",
          width: "150",
        },
      ],
    };
  },
  created() {
    this.loading = true;
    Promise.all([this.getAll(), this.getAllEmployee()]).finally(() => {
      this.loading = false;
    });
  },
  watch: {
    formSearch: {
      deep: true,
      handler: debounce(async function (val) {
        this.page = 1;
        this.getAll();
      }, 300),
    },
  },
  methods: {
    /**   Icon giao dien
     *
     *
     * Quản lý kpi : mdi-format-list-checks
     * {
     *    Danh sách kpi: mdi-view-list
     *    Kpi tăng trưởng thị phần : mdi-notebook
     * }
     *
     */
    goCreate() {
      this.$refs.form.showcreate();
    },
    resetFilter() {
      this.formSearch = this.$options.data.call(this).formSearch;
    },
    async getAllEmployee() {
      try {
        const res = await getNhanVienKinhDoanh();
        this.employers = res.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getAll() {
      try {
        let kpiplans = await Kpiplans.list({
          page: this.page,
          perPage: this.itemsPerPage,
          ...this.formSearch,
        });
        this.tableData = kpiplans.data.data;
        this.pageCount = kpiplans.data.last_page;
      } catch (error) {
        console.log(error);
      }
    },
    changePage(val) {
      console.log(val);
      this.page = val;
      this.getAll();
    },
    async edit(item) {
      this.$refs.form.showFormEdit(item);
    },
    async deleted(item) {
      await Kpiplans.destroy(item.id);
      this.getAll();
    },
    async exportExcel() {
      this.loadingExport = true;
      var dateObj = new Date();
      var month = dateObj.getUTCMonth() + 1; //months from 1-12
      var day = dateObj.getUTCDate();
      var year = dateObj.getUTCFullYear();
      let newdate = day + "/" + month + "/" + year;
      const res = await Kpiplans.kpiplansExport(this.formSearch);

      if (res && res.isDone) {
        saveAs(
          new Blob([res.data]),
          "Danhsachkpitangtruongthiphan" + "-" + newdate + ".xlsx"
        );
      }
      this.loadingExport = false;
    },
    importData() {
      this.$refs.formImport.showFormImport();
    },
    async handle_download_templace() {
      this.$refs.templace.show();
    },
  },
};
</script>
<style scoped>
.header__right__list_item {
  min-height: 40px !important;
  height: 40px !important;
}
.tab_color {
  color: #000;
}
.style--ellipsis {
  max-width: 350px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>