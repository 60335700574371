<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Xuất Templace Excel</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <v-col cols="12">
                  <div class="label-form">Chọn số lượng khách hàng</div>
                  <v-text-field
                    v-model="quantity"
                    :rules="numberRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" @click="dialog = false" depressed>
            Huỷ bỏ
          </v-btn>
          <v-btn color="primary" @click="exportExcel()">
            <v-icon left>mdi-microsoft-excel</v-icon>
            Xuất
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import KPIDETAIL from "@/api/kpidetail";
import { saveAs } from "file-saver";
export default {
  data: () => ({
    numberRules: [
      (v) => Number.isInteger(Number(v)) || "Phải là kiểu số",
      (v) => v > 0 || "Phải lớn hơn 0",
    ],
    dialog: false,
    quantity: null,
  }),
  methods: {
    async exportExcel() {
      if (this.$refs.form.validate()) {
        const res = await KPIDETAIL.kpidetailexport(this.quantity);
        if(res && res.isDone){
          saveAs(new Blob([res.data]), "KpiPlanTemplace.xlsx");
        }
      }
      this.quantity =null;
      this.dialog = false;
    },
    show() {
      this.dialog = true;
    },
  },
  watch: {},
};
</script>