<template>
  <v-dialog v-model="show" persistent width="600">
    <v-card :loading="isLoading">
      <v-card-title class="headline">{{
        "Tải dữ liệu bằng tệp tin excel"
      }}</v-card-title>
      <br />
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="form.year"
                label="Năm "
                :items="yearList"
                :rules="required_rule"
                item-text="name"
                item-value="id"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="form.month"
                label="Tháng "
                :items="monthList"
                :rules="required_rule"
                item-text="name"
                item-value="id"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="form.staff_id"
                label="Nhân viên "
                :items="employers"
                :rules="required_rule"
                item-text="name"
                item-value="id"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <div class="label-form">Chọn hoặc kéo thả file</div>
              <UploadFile v-model="form.file"></UploadFile>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false" class="mr-4">Đóng</v-btn>
        <v-btn
          :disabled="!form.file"
          :loading="isLoading"
          color="primary"
          @click="importKpiPlan"
        >
          <v-icon left>mdi-cloud-upload</v-icon>
          Tải lên
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Kpiplan from "@/api/kpiplan";
import UploadFile from "@/components/upload/UploadFile";
import JsonToFromData from "@/utils/JsonToFormData";
import { getNhanVienKinhDoanh } from "@/api/nhanvien";
import { generateArrayOfYears } from "@/utils/date.js";
import { monthList } from "@/constants/time";
export default {
  components: {
    UploadFile,
  },
  data: () => ({
    isLoading: false,
    show: false,
    yearList: generateArrayOfYears(10),
    monthList,
    employers: [],
    form: {
      year: null,
      month: null,
      staff_id: null,
      file: null,
    },
    required_rule: [(v) => !!v || "Không được bỏ trống"],
  }),
  watch: {
    show(val) {
      if (!val) {
        this.form = this.$options.data.call(this).form;
      }
    },
  },
  created() {
    this.getAllEmployee();
  },
  methods: {
    showFormImport() {
      this.show = true;
      if (this.$refs["files"]) {
        this.$refs["files"].value = null;
      }
    },
    async getAllEmployee() {
      try {
        const res = await getNhanVienKinhDoanh();
        this.employers = res.data;
      } catch (error) {
        console.error(error);
      }
    },
    async importKpiPlan() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        let data = JsonToFromData({ ...this.form });
        console.log(data);
        await Kpiplan.importKpiPlan(data);
        this.isLoading = false;
        this.$emit("on-done");
        this.show = false;
      }
    },
  },
};
</script>
